import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'components/Auth/AuthProtect';
import DashboardLayout from 'layouts/DashboardLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.general.dashboard,
      component: lazy(() => import('views/general/DashboardAppView'))
    },
    {
      exact: true,
      path: PATH_APP.general.addClub,
      component: lazy(() => import('views/general/AddClubView'))
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.general.root} />
    },
    // APP : CALENDAR
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.app.bookings,
      component: lazy(() => import('views/bookings/CalendarView'))
    },
    // MANAGEMENT : USER
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.user.account,
      component: lazy(() => import('views/user/AccountView'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.root,
      component: () => <Redirect to={PATH_APP.management.user.account} />
    },

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to='/404' />
    }
  ]
};

export default AppRoutes;
