import { createSlice } from '@reduxjs/toolkit';
import { firestore } from 'firebase';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  clubs: [],
  club: null
};

const slice = createSlice({
  name: 'club',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Clubs
    getClubsSuccess(state, action) {
      state.isLoading = false;
      state.clubs = action.payload;
    },

    // GET club INFINITE
    getClubsInitial(state, action) {
      state.isLoading = false;
      state.Clubs = action.payload;
    },

    getMoreClubs(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET club
    getClubsuccess(state, action) {
      state.isLoading = false;
      state.club = action.payload;
    },

    // GET RECENT club
    getRecentClubsSuccess(state, action) {
      state.isLoading = false;
      state.recentClubs = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMoreClubs } = slice.actions;

// ----------------------------------------------------------------------

export function getAllClubs(uid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let clubs = [];
      const playbookUser = await firestore().collection('users').doc(uid).get();
      const whenClubsInfoFetched = playbookUser.data().clubs?.map(club => club.get());
      if (whenClubsInfoFetched && whenClubsInfoFetched.length > 0) {
        const clubsInfo = await Promise.all(whenClubsInfoFetched);
        clubs = clubsInfo.map((el) => ({ ...el.data(), id: el.id }));
      }
      dispatch(slice.actions.getClubsSuccess(clubs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
